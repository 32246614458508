import React from "react";
import {
  Alert,
  Box,
  Button,
  ColumnLayout,
  Container,
  Header,
  Link,
  SpaceBetween,
  Spinner,
  TextContent
} from "@cloudscape-design/components";
import * as R from "ramda";
import {
  useResourcesAccountMetadata,
  useResourcesMetadata,
  useResourcesRegionMetadata
} from "../../Hooks/useResourcesMetadata";
import { useAccounts } from "../../Hooks/useAccounts";
import ValueWithLabel from "../../Shared/ValueWithLabel";
import {
  getAppInsightsDashboardLink,
  isUsingOrganizations
} from "../../../Utils/AccountUtils";
const ResourceOverview = () => {
  const { data: resources = { accounts: [] }, isLoading: loadingResources } = useResourcesMetadata();
  const accountsFilter = resources.accounts.map(({ accountId }) => ({
    accountId
  }));
  const { data: importedAccounts, isLoading: isLoadingImportedAccounts } = useAccounts();
  const { data: accounts = [], isLoading: loadingAccounts } = useResourcesAccountMetadata(accountsFilter, { batchSize: 50 });
  const { data: regions = [], isLoading: loadingRegions } = useResourcesRegionMetadata(accountsFilter, { batchSize: 50 });
  const regionCount = R.reduce(
    (acc, val) => R.add(acc, R.length(val.regions)),
    0,
    regions
  );
  return /* @__PURE__ */ React.createElement(
    Container,
    {
      "data-testid": "resources-metadata-overview",
      header: /* @__PURE__ */ React.createElement(Header, null, "Overview")
    },
    loadingAccounts || loadingRegions || loadingResources ? /* @__PURE__ */ React.createElement(Spinner, null) : /* @__PURE__ */ React.createElement(SpaceBetween, { size: "m" }, /* @__PURE__ */ React.createElement(ColumnLayout, { columns: 4, variant: "text-grid" }, /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Resources discovered" }, /* @__PURE__ */ React.createElement(Box, { tagOverride: "p", variant: "h1" }, resources.count ?? "-")), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Resources types" }, /* @__PURE__ */ React.createElement(Box, { tagOverride: "p", variant: "h1" }, resources.resourceTypes?.length ?? "-")), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Accounts" }, /* @__PURE__ */ React.createElement(Box, { tagOverride: "p", variant: "h1" }, R.length(accounts))), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Regions" }, /* @__PURE__ */ React.createElement(Box, { tagOverride: "p", variant: "h1" }, regionCount))), !isLoadingImportedAccounts && getWarningBanner(resources, importedAccounts))
  );
};
const getWarningBanner = (resources, importedAccounts) => {
  if (!isUsingOrganizations() && importedAccounts.length === 0) {
    return /* @__PURE__ */ React.createElement(
      Alert,
      {
        header: "No accounts imported",
        statusIconAriaLabel: "Warning",
        type: "warning",
        action: /* @__PURE__ */ React.createElement(Button, { href: "/accounts", variant: "external-link" }, "Import an account")
      },
      /* @__PURE__ */ React.createElement(TextContent, null, "Import an account to discover resources in that account."),
      /* @__PURE__ */ React.createElement(
        Link,
        {
          variant: "secondary",
          external: true,
          href: "https://docs.aws.amazon.com/solutions/latest/workload-discovery-on-aws/import-a-region.html"
        },
        "Learn more about importing accounts"
      )
    );
  }
  if (importedAccounts.some(({ isIamRoleDeployed }) => !isIamRoleDeployed)) {
    return /* @__PURE__ */ React.createElement(
      Alert,
      {
        header: "Missing IAM Role(s)",
        statusIconAriaLabel: "Warning",
        type: "warning",
        action: /* @__PURE__ */ React.createElement(Button, { href: "/accounts", variant: "external-link" }, "View account configuration")
      },
      !isUsingOrganizations() ? /* @__PURE__ */ React.createElement(TextContent, null, "At least one account is missing the Workload Discovery IAM Role. Visit the Accounts page to see what accounts are affected and verify that the AWS CloudFormation StackSets stack instance in these accounts deployed correctly.") : /* @__PURE__ */ React.createElement(TextContent, null, "At least one account is missing the Workload Discovery IAM Role. Visit the Accounts page to see what accounts are affected and deploy the global resources template in those accounts"),
      /* @__PURE__ */ React.createElement(
        Link,
        {
          external: true,
          href: "https://docs.aws.amazon.com/solutions/latest/workload-discovery-on-aws/import-a-region.html"
        },
        "Learn more about importing accounts"
      )
    );
  }
  if (resources.count === 0 && (importedAccounts.length > 0 || isUsingOrganizations())) {
    return /* @__PURE__ */ React.createElement(
      Alert,
      {
        header: "No resources discovered",
        statusIconAriaLabel: "Error",
        type: "error"
      },
      /* @__PURE__ */ React.createElement(TextContent, null, "The resource discovery process was unable to locate any resources. This may be due to an issue with the deployment configuration."),
      /* @__PURE__ */ React.createElement("br", null),
      /* @__PURE__ */ React.createElement(TextContent, null, "Recommended actions:"),
      /* @__PURE__ */ React.createElement("ol", null, /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(Link, { external: true, href: getAppInsightsDashboardLink() }, "View the Application Insights dashboard for detailed error information")), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(
        Link,
        {
          external: true,
          href: "https://aws-solutions.github.io/workload-discovery-on-aws/workload-discovery-on-aws/2.0/debugging-the-discovery-component.html"
        },
        "Review the discovery process debugging guide"
      )))
    );
  }
  return null;
};
export default ResourceOverview;
