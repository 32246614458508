import React from "react";
import { Button, FormField, Multiselect } from "@cloudscape-design/components";
import * as R from "ramda";
import {
  useResourcesMetadata,
  useResourcesRegionMetadata
} from "../../../../Hooks/useResourcesMetadata";
import { useDeepCompareEffect } from "react-use";
import { useAccounts } from "../../../../Hooks/useAccounts";
const AccountMultiSelect = ({
  selected = [],
  onChange = () => ({}),
  onOptionsChange = () => ({}),
  disabled = false
}) => {
  const { data: accounts = [] } = useAccounts();
  const accountsFilter = accounts.map(({ accountId }) => ({
    accountId
  }));
  const accountNameToIdMap = new Map(accounts.map((x) => [x.accountId, x.name]));
  const { data: accountsRegionMetadata = [] } = useResourcesRegionMetadata(
    accountsFilter,
    {
      batchSize: 50
    }
  );
  useDeepCompareEffect(() => {
    onOptionsChange(accountsRegionMetadata.map((i) => i.accountId));
  }, [accountsRegionMetadata, onOptionsChange]);
  const options = accountsRegionMetadata.map((i, index) => {
    const name = accountNameToIdMap.get(i.accountId);
    return {
      label: name == null ? i.accountId : `${name} (${i.accountId})`,
      value: i.accountId,
      tags: [`${R.length(i.regions)} regions`, `${i.count} resources`]
    };
  });
  const handleSelectAll = () => {
    onChange(R.uniq(R.map((i) => i.value, options)));
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    FormField,
    {
      label: "Accounts",
      secondaryControl: /* @__PURE__ */ React.createElement(Button, { onClick: handleSelectAll }, "Choose All")
    },
    /* @__PURE__ */ React.createElement(
      Multiselect,
      {
        filteringType: "auto",
        disabled,
        options,
        selectedOptions: options.filter(
          (i) => selected.indexOf(i.value) !== -1
        ),
        onChange: (e) => onChange(e.detail.selectedOptions.map((i) => i.value)),
        placeholder: "Choose accounts to filter by",
        tokenLimit: 5,
        i18nStrings: {
          tokenLimitShowMore: "Show more chosen options",
          tokenLimitShowFewer: "Show fewer chosen options"
        }
      }
    )
  ));
};
export default AccountMultiSelect;
