export const AWS_ORGANIZATIONS = "AWS_ORGANIZATIONS";
export const GLOBAL_RESOURCES_TEMPLATE_FILENAME = "global-resources.template";
export const DEFAULT_COSTS_INTERVAL = {
  type: "relative",
  unit: "day",
  amount: 5
};
export const PSEUDO_RESOURCE_TYPES = /* @__PURE__ */ new Set([
  "AWS::Tags::Tag",
  "AWS::IAM::InlinePolicy"
]);
export const IMPORT_CSV = "csv";
export const IMPORT_INPUT = "input";
