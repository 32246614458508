import React, { useState } from "react";
import {
  Box,
  Button,
  TextFilter,
  Header,
  Pagination,
  Table,
  SpaceBetween,
  Modal,
  StatusIndicator,
  Alert,
  Select,
  Grid
} from "@cloudscape-design/components";
import PropTypes from "prop-types";
import { IMPORT } from "../../../routes";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { useHistory } from "react-router-dom";
import { useAccounts, useRemoveAccount } from "../../Hooks/useAccounts";
import { GLOBAL_TEMPLATE, useTemplate } from "../../Hooks/useTemplate";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import * as R from "ramda";
import { useDeepCompareEffect } from "react-use";
import { isUsingOrganizations } from "../../../Utils/AccountUtils";
import fileDownload from "js-file-download";
import { GLOBAL_RESOURCES_TEMPLATE_FILENAME } from "../../../config/constants";
import { createTableAriaLabels } from "../../../Utils/AccessibilityUtils";
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
const columns = [
  {
    id: "account",
    header: "Account Id",
    cell: (e) => e.accountId,
    sortingField: "accountId",
    width: 200,
    minWidth: 200
  },
  {
    id: "accountName",
    header: "Account name",
    cell: (e) => e.name,
    sortingField: "accountName",
    width: 300,
    minWidth: 300
  },
  {
    id: "regions",
    header: "Regions",
    cell: (e) => e.regionCount,
    width: 125,
    minWidth: 125
  },
  {
    id: "iamRoleStatus",
    header: "Role Status",
    cell: (e) => {
      if (e.isIamRoleDeployed == null)
        return /* @__PURE__ */ React.createElement(StatusIndicator, { type: "info" }, "Unknown");
      return e.isIamRoleDeployed === false ? /* @__PURE__ */ React.createElement(StatusIndicator, { type: "error" }, "Not Deployed") : /* @__PURE__ */ React.createElement(StatusIndicator, { type: "success" }, "Deployed");
    },
    width: 200,
    minWidth: 200
  },
  {
    id: "lastDiscovered",
    header: "Last Discovered",
    cell: (e) => {
      if (e.isIamRoleDeployed === false && e.lastCrawled == null) {
        return /* @__PURE__ */ React.createElement(StatusIndicator, { type: "error" }, "Not Discoverable");
      } else if (e.isIamRoleDeployed == null) {
        return /* @__PURE__ */ React.createElement(StatusIndicator, { type: "info" }, "Awaiting Discovery");
      } else {
        return dayjs().to(dayjs(e.lastCrawled));
      }
    },
    width: 200,
    minWidth: 200
  }
];
const defaultRoleStatus = { value: "0", label: "Any Role Status" };
const selectRoleStatusOptions = [
  defaultRoleStatus,
  { value: "1", label: "Unknown" },
  { value: "2", label: "Deployed" },
  { value: "3", label: "Not Deployed" }
];
function matchesRoleStatus(item, selectedRoleStatus) {
  switch (selectedRoleStatus.label) {
    case "Unknown":
      return item.isIamRoleDeployed == null;
    case "Deployed":
      return item.isIamRoleDeployed === true;
    case "Not Deployed":
      return item.isIamRoleDeployed === false;
    default:
      return true;
  }
}
function DownloadButton({ globalTemplate }) {
  return /* @__PURE__ */ React.createElement(
    Button,
    {
      iconName: "download",
      onClick: async () => fileDownload(globalTemplate, GLOBAL_RESOURCES_TEMPLATE_FILENAME)
    },
    "Download global resources template"
  );
}
function SelfManagedAccountsAlert({ globalTemplate }) {
  return /* @__PURE__ */ React.createElement(
    Alert,
    {
      type: "warning",
      statusIconAriaLabel: "Warning",
      action: DownloadButton({ globalTemplate }),
      header: "Undiscovered Accounts"
    },
    "The global resources CloudFormation templates have not been deployed to one or more accounts. You must provision this template in these accounts to make them discoverable by Workload Discovery. You can filter the account list by selecting ",
    /* @__PURE__ */ React.createElement("b", null, "Not Deployed"),
    " from the Role Status dropdown menu to determine which accounts must be updated. Choose ",
    /* @__PURE__ */ React.createElement("b", null, "Download global resources template"),
    " and deploy the template in each of the affected accounts."
  );
}
function OrganizationsManagedAccounts({ globalTemplate, accounts }) {
  const managementAccount = accounts.find(
    (x) => x.isManagementAccount === true
  );
  const noIamDeployedAccounts = accounts.filter(
    (x) => x.isIamRoleDeployed === false && !x.isManagementAccount
  );
  return /* @__PURE__ */ React.createElement(SpaceBetween, { size: "xxs" }, R.isEmpty(noIamDeployedAccounts) ? void 0 : /* @__PURE__ */ React.createElement(
    Alert,
    {
      type: "warning",
      statusIconAriaLabel: "Warning",
      header: "Undiscovered Accounts"
    },
    "The global resources CloudFormation templates have not been deployed to one or more accounts. These are provisioned by the ",
    /* @__PURE__ */ React.createElement("b", null, "WdGlobalResources"),
    " StackSet in the account and region that Workload Discovery has been deployed to. Verify that all the stack instances in the ",
    /* @__PURE__ */ React.createElement("b", null, "WdGlobalResources"),
    " ",
    "StackSet have deployed successfully."
  ), managementAccount == null || managementAccount.isIamRoleDeployed === true ? void 0 : /* @__PURE__ */ React.createElement(
    Alert,
    {
      type: "warning",
      statusIconAriaLabel: "Warning",
      action: DownloadButton({ globalTemplate }),
      header: "Management Account Undiscovered"
    },
    "The global resources CloudFormation template has not been deployed to the AWS Organizations management account. You must provision this template to make this account discoverable by Workload Discovery. Choose",
    " ",
    /* @__PURE__ */ React.createElement("b", null, "Download global resources template"),
    " and deploy the template to the management account."
  ));
}
function AccountAlert({ globalTemplate, accounts }) {
  return isUsingOrganizations() ? /* @__PURE__ */ React.createElement(
    OrganizationsManagedAccounts,
    {
      globalTemplate,
      accounts
    }
  ) : /* @__PURE__ */ React.createElement(SelfManagedAccountsAlert, { globalTemplate });
}
const DiscoverableAccountsTable = ({ selectedAccounts, onSelect }) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [roleStatus, setRoleStatus] = useState(defaultRoleStatus);
  const { data = [], isLoading } = useAccounts();
  const { data: globalTemplate } = useTemplate(GLOBAL_TEMPLATE);
  const { removeAsync } = useRemoveAccount();
  const history = useHistory();
  useDeepCompareEffect(() => {
    onSelect(
      selectedAccounts.filter(
        (i) => data.find((j) => i.accountId === j.accountId)
      )
    );
  }, [data, onSelect, selectedAccounts]);
  const accounts = R.map(
    ({ accountId, name, regions, ...props }) => ({
      id: accountId + name,
      accountId,
      name,
      regionCount: R.length(regions),
      regions,
      ...props
    }),
    data
  );
  const noIamDeployedAccounts = accounts.filter(
    (x) => x.isIamRoleDeployed === false
  );
  const onSelectionChange = (items2) => {
    onSelect(items2);
  };
  const { items, filterProps, collectionProps, paginationProps } = useCollection(accounts, {
    filtering: {
      empty: /* @__PURE__ */ React.createElement(Box, { textAlign: "center", color: "inherit" }, /* @__PURE__ */ React.createElement("b", null, "No Accounts"), /* @__PURE__ */ React.createElement(
        Box,
        {
          padding: { bottom: "s" },
          variant: "p",
          color: "inherit"
        },
        "No Account matched filter"
      )),
      noMatch: /* @__PURE__ */ React.createElement(Box, { textAlign: "center", color: "inherit" }, /* @__PURE__ */ React.createElement("b", null, "No Account"), /* @__PURE__ */ React.createElement(
        Box,
        {
          padding: { bottom: "s" },
          variant: "p",
          color: "inherit"
        },
        "No Account matched filter"
      )),
      filteringFunction: (item, filteringText) => {
        return (item.accountId.includes(filteringText) || item.name.includes(filteringText)) && matchesRoleStatus(item, roleStatus);
      }
    },
    pagination: { pageSize: 10 },
    sorting: { sortingColumn: "accountId" }
  });
  const handleDelete = () => {
    return removeAsync(selectedAccounts.map((i) => i.accountId)).then(
      () => setShowDeleteConfirm(false)
    );
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    Modal,
    {
      onDismiss: () => setShowDeleteConfirm(false),
      visible: showDeleteConfirm,
      closeAriaLabel: "Close modal",
      footer: /* @__PURE__ */ React.createElement(Box, { float: "right" }, /* @__PURE__ */ React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" }, /* @__PURE__ */ React.createElement(
        Button,
        {
          onClick: () => setShowDeleteConfirm(false),
          variant: "link"
        },
        "Cancel"
      ), /* @__PURE__ */ React.createElement(Button, { onClick: handleDelete, variant: "primary" }, "Delete"))),
      header: "Remove Account"
    },
    "Remove the AWS account",
    " ",
    /* @__PURE__ */ React.createElement("strong", null, selectedAccounts.map((i) => R.defaultTo(i.accountId, i.accountName)).join(", ")),
    "?"
  ), /* @__PURE__ */ React.createElement(SpaceBetween, { size: "s" }, R.isEmpty(noIamDeployedAccounts) ? void 0 : /* @__PURE__ */ React.createElement(
    AccountAlert,
    {
      globalTemplate,
      accounts
    }
  ), /* @__PURE__ */ React.createElement(
    Table,
    {
      ariaLabels: createTableAriaLabels(
        "account",
        "accounts",
        {
          keys: ["accountId"],
          fallback: "Unknown account"
        },
        "Accounts"
      ),
      ...collectionProps,
      header: /* @__PURE__ */ React.createElement(
        Header,
        {
          actions: isUsingOrganizations() ? void 0 : /* @__PURE__ */ React.createElement(
            SpaceBetween,
            {
              direction: "horizontal",
              size: "xs"
            },
            /* @__PURE__ */ React.createElement(
              Button,
              {
                disabled: R.isEmpty(
                  selectedAccounts
                ),
                onClick: () => setShowDeleteConfirm(true)
              },
              "Remove"
            ),
            /* @__PURE__ */ React.createElement(
              Button,
              {
                loadingText: "Removing",
                variant: "primary",
                onClick: (e) => {
                  e.preventDefault();
                  history.push(IMPORT);
                }
              },
              "Import"
            )
          ),
          variant: "h2",
          description: "AWS accounts that contain Regions imported into Workload Discovery on AWS"
        },
        "Accounts"
      ),
      loading: isLoading,
      trackBy: "id",
      resizableColumns: true,
      columnDefinitions: columns,
      items,
      selectedItems: selectedAccounts,
      selectionType: "multi",
      onSelectionChange: (evt) => onSelectionChange(evt.detail.selectedItems),
      loadingText: "Loading accounts",
      filter: /* @__PURE__ */ React.createElement(
        Grid,
        {
          gridDefinition: [
            { colspan: { default: 3, xxs: 9 } },
            { colspan: { default: 9, xxs: 3 } }
          ]
        },
        /* @__PURE__ */ React.createElement(
          TextFilter,
          {
            ...filterProps,
            filteringPlaceholder: "Find an Account..."
          }
        ),
        /* @__PURE__ */ React.createElement(
          Select,
          {
            options: selectRoleStatusOptions,
            selectedAriaLabel: "Selected",
            selectedOption: roleStatus,
            onChange: (event) => setRoleStatus(event.detail.selectedOption),
            ariaDescribedby: null,
            expandToViewport: true
          }
        )
      ),
      pagination: /* @__PURE__ */ React.createElement(Pagination, { ...paginationProps })
    }
  )));
};
DiscoverableAccountsTable.propTypes = {
  onSelect: PropTypes.func.isRequired
};
export default DiscoverableAccountsTable;
